import { gql } from '@apollo/client';

export const PROFILE_FRAGMENT = gql`
  fragment ProfileFields on Profile {
    userId
    lastWrite
    deletedAt
    avatarId
  }
`;

export const PROFILE = gql`
  ${PROFILE_FRAGMENT}
  query Profile {
    profile {
      ...ProfileFields
    }
  }
`;

export const UPDATE_AVATAR = gql`
  ${PROFILE_FRAGMENT}
  mutation UpdateAvatarId($avatarId: String!) {
    updateAvatarId(avatarId: $avatarId) {
      ...ProfileFields
    }
  }
`;
