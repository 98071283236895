import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';

const { REACT_APP_ACCOUNT_ENDPOINT, REACT_APP_INSTRUCTOR_ENDPOINT } =
  process.env;

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }

    return forward(operation);
  });
}

function omitTypename(key: any, value: any) {
  return key === '__typename' ? undefined : value;
}

const authLink = createHttpLink({
  uri: REACT_APP_ACCOUNT_ENDPOINT,
  credentials: 'include',
});

const ommitedAuthLink = ApolloLink.from([createOmitTypenameLink(), authLink]);

const instructorLink = createHttpLink({
  uri: REACT_APP_INSTRUCTOR_ENDPOINT,
  credentials: 'include',
});

const ommitedInstructorLink = ApolloLink.from([
  createOmitTypenameLink(),
  instructorLink,
]);

export const authClient = new ApolloClient({
  link: ommitedAuthLink,
  cache: new InMemoryCache(),
});

export const instructorClient = new ApolloClient({
  link: ommitedInstructorLink,
  cache: new InMemoryCache(),
});
