/* eslint-disable no-restricted-globals */
import React, { FC, Suspense, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  Canvas,
  ObjectMap,
  useFrame,
  useLoader,
  useThree,
} from '@react-three/fiber';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { Group } from 'three';
import { ErrorBoundary } from 'react-error-boundary';

function makeAvatarUrl(avatarId: string) {
  return `https://models.readyplayer.me/${avatarId}.glb`;
}

type AvatarProps = {
  avatarId: string;
};

let gltf: GLTF & ObjectMap;

const Avatar: FC<AvatarProps> = ({ avatarId }: AvatarProps) => {
  const scene = useThree((status) => status.scene);

  gltf = useLoader(GLTFLoader, makeAvatarUrl(avatarId));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (gltf) {
      const group = new Group();
      group.name = 'AvatarGroup';
      scene.add(group);

      // eslint-disable-next-line no-console
      console.log(gltf.nodes);

      group.add(gltf.scene);

      return () => {
        group?.clear();
      };
    }
  }, [gltf]);

  let mixer: THREE.AnimationMixer;

  if (gltf) {
    gltf.nodes.RightHand.scale.x = 0;
    gltf.nodes.RightHand.scale.y = 0;
    gltf.nodes.RightHand.scale.z = 0;

    gltf.nodes.LeftHand.scale.x = 0;
    gltf.nodes.LeftHand.scale.y = 0;
    gltf.nodes.LeftHand.scale.z = 0;

    gltf.nodes.AvatarRoot.scale.x = 12;
    gltf.nodes.AvatarRoot.scale.y = 12;
    gltf.nodes.AvatarRoot.scale.z = 12;
    gltf.nodes.AvatarRoot.position.y = -7;
    gltf.nodes.AvatarRoot.rotation.y = 0.3490659;
    gltf.nodes.Head.rotation.x = 0.1745329;

    if (gltf.animations.length) {
      mixer = new THREE.AnimationMixer(gltf.scene);

      const anim = gltf.animations.find((a) => a.name == 'idle_eyes');

      if (anim) {
        const action = mixer.clipAction(anim);
        action.timeScale = 1 / 2;
        action.play();
      }
    }
  }

  useFrame((state, delta) => {
    mixer?.update(delta);
  });

  return null;
};

const AvatarFallback = () => <></>;

const AvatarViewer: FC<AvatarProps> = ({ avatarId }: AvatarProps) => (
  <ErrorBoundary FallbackComponent={AvatarFallback}>
    <Suspense
      fallback={
        <div
          className="p-grid p-justify-center p-align-center"
          style={{ width: '100%', height: '100%' }}
        >
          <ProgressSpinner />
        </div>
      }
    >
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />

        {avatarId ? <Avatar avatarId={avatarId} /> : <></>}
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
        />
      </Canvas>
    </Suspense>
  </ErrorBoundary>
);

export default AvatarViewer;
