import { gql } from '@apollo/client';

export const INSTRUCTION_TRANSLATION_FRAGMENT = gql`
  fragment InstructionTranslationFields on InstructionTranslation {
    guid
    lang
    name
    description
    protocol
  }
`;

export const INSTRUCTION_FRAGMENT = gql`
  ${INSTRUCTION_TRANSLATION_FRAGMENT}
  fragment InstructionFields on Instruction {
    guid
    organizationId
    state
    required
    categoryGuid
    authorId
    tags
    modelId
    qrCode
    originGuid
    requestDate
    translations {
      ...InstructionTranslationFields
    }
  }
`;

export const INSTRUCTIONS = gql`
  ${INSTRUCTION_FRAGMENT}
  query ($guids: [String!], $name: String) {
    instructions(guids: $guids, name: $name) {
      ...InstructionFields
    }
  }
`;
