import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { store } from './stores';
import LoginProvider from './provider/LoginProvider';
import LocalizationProvider from './provider/LocalizationProvider';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoginProvider>
        <LocalizationProvider>
          <Router>
            <AppRouter />
          </Router>
        </LocalizationProvider>
      </LoginProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals();
