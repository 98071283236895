import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { User } from '../types/User';
import { setUser } from '../stores/authSlice';
import { LOGIN_EXTERNAL, REFRESH_TOKEN } from '../graphql/Auth';
import { authClient } from '../apollo';

const LoginProvider: FC = ({ children }: PropsWithChildren<{}>) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const refreshToken = useCallback(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.token) {
      authClient
        .mutate<{ loginExternal: User }>({
          mutation: LOGIN_EXTERNAL,
          variables: { token: params.token },
        })
        .then(({ data }) => {
          if (data) dispatch(setUser(data.loginExternal));
          setLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          setLoading(false);
        });
    } else {
      authClient
        .mutate<{ refreshToken: User }>({ mutation: REFRESH_TOKEN })
        .then(({ data }) => {
          if (data) dispatch(setUser(data.refreshToken));
          setLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          setLoading(false);
        });
    }
  }, [dispatch]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  if (loading) return null;

  return <>{children}</>;
};

export default LoginProvider;
