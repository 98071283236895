import React, { FC } from 'react';

import { InputText, InputTextProps } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Trans } from '@lingui/react';

type ExtraProps = {
  error?: string | undefined;
  translatedLabel?: string | undefined;
};

const extraProps: ExtraProps = { error: '', translatedLabel: '' };

type TepInputTextProps = ExtraProps & InputTextProps;

const TepInputText: FC<TepInputTextProps> = (props: TepInputTextProps) => {
  const { className, error, translatedLabel } = props;

  function getInputTextProps() {
    const anyProps: any = props;
    const extraPropsKeys = Object.keys(extraProps);

    return Object.keys(props)
      .filter((key) => !extraPropsKeys.includes(key))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: anyProps[key],
        }),
        {}
      );
  }

  return (
    <span className="p-float-label">
      <InputText
        {...getInputTextProps()}
        className={classNames(className, {
          'p-invalid': error,
        })}
      />
      <label
        htmlFor="name"
        className={classNames({
          'p-error': error,
        })}
      >
        {translatedLabel && <Trans id={translatedLabel} />}
      </label>
    </span>
  );
};

TepInputText.defaultProps = { error: undefined, translatedLabel: undefined };

export default TepInputText;
