/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import { classNames } from 'primereact/utils';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Trans } from '@lingui/macro';
import { Dialog } from 'primereact/dialog';
import { i18n } from '@lingui/core';
import { Button } from 'primereact/button';
import { instructorClient } from '../../apollo';
import { PROFILE, UPDATE_AVATAR } from '../../graphql/Profile';
import { Profile } from '../../types/Profile';
import AvatarViewer from '../../components/three/AvatarViewer';
import ReadyPlayerMe from './ReadyPlayerMe';

import './profile.scss';

const embedded = location.pathname.includes('embed');

function parseAvatarUrl(url: string) {
  let parsedUrl = url.replace('https://models.readyplayer.me/', '');
  parsedUrl = parsedUrl.replace('.glb', '');

  return parsedUrl;
}

const ProfileView: FC = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [displayDialog, setDisplayDialog] = useState(false);

  const profileQuery = useCallback(async () => {
    instructorClient
      .query<{ profile: Profile }>({ query: PROFILE })
      .then(({ data }) => {
        setProfile(data.profile);
        setLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    profileQuery();
  }, [profileQuery]);

  function onHide() {
    setDisplayDialog(false);
  }

  const onAvatarExport = (url: string) => {
    const parsedUrl = parseAvatarUrl(url);

    instructorClient
      .mutate<{ updateAvatarId: Profile }>({
        mutation: UPDATE_AVATAR,
        variables: { avatarId: parsedUrl },
      })
      .then(({ data }) => {
        if (data) {
          setProfile(data.updateAvatarId);
          setDisplayDialog(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <div
      className={classNames('card p-d-flex p-flex-column avatar-background', {
        'p-input-filled': embedded,
      })}
      style={{
        flex: '1 1 auto',
        height: embedded ? '100vh' : '',
        background: 'url(/images/Classroom.jpg',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      {!profile?.avatarId ? (
        <></>
      ) : loading ? (
        <ProgressSpinner
          className="p-d-block p-mx-auto"
          style={{ height: '100%' }}
        />
      ) : (
        <div
          style={{
            zIndex: 2,
            height: '100%',
            marginRight: '-0.5rem',
            marginTop: '0.5rem',
            marginLeft: '0.5rem',
          }}
        >
          <div
            className="p-grid p-align-center"
            style={{ height: '100%', maxWidth: '100%' }}
          >
            <div className="p-col-12 p-md-6 p-lg-6 p-d-flex avatar">
              <div
                className="p-d-flex"
                style={{
                  width: '100%',
                  marginLeft: '-14px',
                  marginRight: '-14px',
                }}
              >
                <AvatarViewer key="keywaka" avatarId={profile?.avatarId} />
              </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-6 p-justify-start">
              <div
                className="card p-d-flex"
                style={{ maxWidth: '50rem', minHeight: '30rem' }}
              >
                <div
                  className="p-grid p-dir-col"
                  style={{ marginBottom: '-0.5rem', width: '100%' }}
                >
                  <div className="p-col ws-pl">
                    <h2 style={{ marginTop: '0' }}>
                      <Trans>Profile</Trans>
                    </h2>
                    <Trans>Profile.InfoText</Trans>
                  </div>
                  <div className="p-col-fixed p-justify-center p-d-flex">
                    <Button
                      label={i18n._('Avatar.Edit')}
                      onClick={() => setDisplayDialog(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog
        header={i18n._('Avatar.Edit')}
        visible={displayDialog}
        style={{ width: '95vw', height: '95vh', overflow: 'hidden' }}
        onHide={onHide}
        appendTo="self"
      >
        <div style={{ overflow: 'hidden', height: '100%' }}>
          <ReadyPlayerMe onAvatarExport={onAvatarExport} />
        </div>
      </Dialog>
    </div>
  );
};

export default ProfileView;
