import React, { ReactElement } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import Switch from './components/Switch';

import Profile from '../views/Profile';
import Meeting from '../views/Meeting';
import Authorized from './components/Autorized';
import NotAuthorized from '../views/Errors/401';
import MainLayout from '../layouts/MainLayout';
import NotFound from '../views/Errors/404';

export default function AppRouter(): ReactElement {
  return (
    <Router>
      <Switch>
        <Route exact path="/404">
          <NotFound />
        </Route>
        <Route exact path="/403">
          <NotAuthorized />
        </Route>
        <Route path="/embed">
          <Switch>
            <Authorized exact path="/embed/profile">
              <Profile />
            </Authorized>
            <Authorized exact path="/embed/meetings">
              <Meeting />
            </Authorized>
          </Switch>
        </Route>
        <Route path="/">
          <MainLayout>
            <Switch>
              <Authorized exact path="/profile">
                <Profile />
              </Authorized>
              <Authorized exact path="/meetings">
                <Meeting />
              </Authorized>
            </Switch>
          </MainLayout>
        </Route>
      </Switch>
    </Router>
  );
}
