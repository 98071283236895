import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RootState } from '../../stores/index';

const Authorized: FC<RouteProps> = ({ children, path }: RouteProps) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const loggedIn = !!user;

  return (
    <Route
      path={path}
      render={() => (loggedIn ? <>{children}</> : <Redirect to="/403" />)}
    />
  );
};

export default Authorized;
