import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './User';

export const LOGIN = gql`
  ${USER_FRAGMENT}
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...UserFields
    }
  }
`;

export const LOGIN_EXTERNAL = gql`
  ${USER_FRAGMENT}
  mutation ($token: String!) {
    loginExternal(token: $token) {
      ...UserFields
    }
  }
`;

export const REFRESH_TOKEN = gql`
  ${USER_FRAGMENT}
  mutation {
    refreshToken {
      ...UserFields
    }
  }
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;
