import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { LOGIN } from '../../graphql/Auth';
import { User } from '../../types/User';
import { setUser } from '../../stores/authSlice';
import { authClient } from '../../apollo';

import './LoginPanel.scss';

const LoginPanel: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const formValues = {
    email: '',
    password: '',
  };

  function onSubmit(values: typeof formValues): void {
    authClient
      .mutate<{ login: User }>({
        mutation: LOGIN,
        variables: values,
      })
      .then(async ({ data }) => {
        if (data) {
          dispatch(setUser(data.login));
          history.push('/');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  return (
    <Card className="login-panel p-d-block p-mx-auto">
      <Formik onSubmit={onSubmit} initialValues={formValues}>
        {({ handleSubmit, handleChange }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="username">Username</label>
                <InputText
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Username"
                  onChange={handleChange}
                />
              </div>
              <div className="p-field">
                <label htmlFor="password">Password</label>
                <InputText
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
              <Button label="Login" icon="pi pi-check" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default LoginPanel;
