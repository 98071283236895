import { gql } from '@apollo/client';

export const MEETING_FRAGMENT = gql`
  fragment MeetingFields on Meeting {
    guid
    organizationId
    lastWrite
    deletedAt
    ownerId
    name
    description
    lang
    start
    end
    moderators {
      users
      groups
    }
    moderatorIds
    attendees {
      users
      groups
    }
    instruction
    room
  }
`;

export const MEETINGS = gql`
  ${MEETING_FRAGMENT}
  query GetMeetings($minDate: DateTime, $maxDate: DateTime) {
    meetings(minDate: $minDate, maxDate: $maxDate) {
      ...MeetingFields
    }
  }
`;

export const ADD_UPDATE_MEETING = gql`
  ${MEETING_FRAGMENT}
  mutation (
    $guid: String
    $ownerId: Float
    $name: String
    $description: String
    $lang: String
    $start: DateTime
    $end: DateTime
    $moderators: UsersGroupsInput
    $attendees: UsersGroupsInput
    $instruction: String
    $room: String
  ) {
    addUpdateMeeting(
      guid: $guid
      ownerId: $ownerId
      name: $name
      description: $description
      lang: $lang
      start: $start
      end: $end
      moderators: $moderators
      attendees: $attendees
      instruction: $instruction
      room: $room
    ) {
      ...MeetingFields
    }
  }
`;

export const REMOVE_MEETING = gql`
  ${MEETING_FRAGMENT}
  mutation ($guid: String!) {
    removeMeeting(guid: $guid) {
      ...MeetingFields
    }
  }
`;
