import React, { FC } from 'react';
import LoginPanel from '../../../components/auth/LoginPanel';

const NotAuthorized: FC = () => (
  <div>
    <LoginPanel />
  </div>
);

export default NotAuthorized;
