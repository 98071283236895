import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteProps,
} from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { Trans } from '@lingui/react';
import { Instruction } from '../../types/Instruction';
import { INSTRUCTIONS } from '../../graphql/Instruction';
import { instructorClient } from '../../apollo';

type Item = {
  label: string;
  value: string;
};

type InstructionAutoCompleteProps = Omit<AutoCompleteProps, 'value'> & {
  value: string[] | string;
  error?: string | undefined;
  translatedLabel?: string | undefined;
  onChange?: (arg: AutoCompleteChangeParams) => void;
};

const InstructionAutoComplete: FC<InstructionAutoCompleteProps> = (
  props: InstructionAutoCompleteProps
) => {
  const { multiple } = props;

  const { error: validationError, translatedLabel } = props;

  const [filteredInstructions, setFilteredInstructions] = useState<
    Item[] | undefined
  >(undefined);

  const [selection, setSelection] = useState<Item[] | Item | undefined>(
    undefined
  );

  const updateInstructionDisplay = useCallback(async () => {
    const { value } = props;

    if (value && value.length > 0) {
      await instructorClient
        .query<{ instructions: Instruction[] }>({
          query: INSTRUCTIONS,
          variables: { guids: Array.isArray(value) ? value : [value] },
        })
        .then(({ data }) => {
          const mappedInstructions = data.instructions.map((instruction) => ({
            label: `${
              instruction.translations.find((x) => x.lang == 'de')?.name
            }`,
            value: instruction.guid,
          }));

          if (multiple) {
            setSelection(mappedInstructions);
          } else {
            setSelection(mappedInstructions[0]);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    updateInstructionDisplay();
  }, [updateInstructionDisplay]);

  function handleChange(arg: AutoCompleteChangeParams) {
    const { value } = arg;
    const { onChange } = props;

    setSelection(value);

    const mappedValues = multiple
      ? value.map((v: Item) => v.value.toString())
      : value?.value;

    if (onChange)
      onChange({
        ...arg,
        value: mappedValues,
        target: { ...arg.target, value: mappedValues },
      });
  }

  const searchInstructions = (event: AutoCompleteCompleteMethodParams) => {
    instructorClient
      .query<{ instructions: Instruction[] }>({
        query: INSTRUCTIONS,
        variables: { name: event.query },
      })
      .then(({ data }) => {
        const foundInstructions = data.instructions.map((instruction) => ({
          label: `${
            instruction.translations.find((x) => x.lang == 'de')?.name
          }`,
          value: instruction.guid,
        }));

        setFilteredInstructions(foundInstructions);
      });
  };

  const customSelectedItem = (item: Item) => (
    <span
      className="p-chips-token-label"
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: '27.5px',
        fontSize: '100% !important',
      }}
    >
      {item.label}
    </span>
  );

  return (
    <span className="p-float-label">
      <AutoComplete
        {...props}
        field="label"
        value={selection}
        suggestions={filteredInstructions}
        completeMethod={searchInstructions}
        onChange={handleChange}
        selectedItemTemplate={multiple ? customSelectedItem : undefined}
      />
      <label
        htmlFor="name"
        className={classNames({
          'p-error': validationError,
        })}
      >
        {translatedLabel && <Trans id={translatedLabel} />}
      </label>
    </span>
  );
};

export default InstructionAutoComplete;
