import React, { FC } from 'react';
import {
  Redirect,
  Switch as RouterSwitch,
  SwitchProps,
} from 'react-router-dom';

const Switch: FC<SwitchProps> = (props: SwitchProps) => {
  const { children } = props;
  return (
    <RouterSwitch>
      {children}
      <Redirect to="/404" />
    </RouterSwitch>
  );
};

export default Switch;
