import { gql } from '@apollo/client';

export const MEETING_GROUP_FRAGMENT = gql`
  fragment MeetingGroupFields on MeetingGroup {
    guid
    name
    users
  }
`;

export const MEETING_GROUPS = gql`
  ${MEETING_GROUP_FRAGMENT}
  query ($guids: [String!], $name: String) {
    meetingGroups(guids: $guids, name: $name) {
      ...MeetingGroupFields
    }
  }
`;
