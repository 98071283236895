import React, { FC, PropsWithChildren, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en, de } from 'make-plural/plurals';

export const locales = {
  en: 'English',
  de: 'Deutsch',
};
export const defaultLocale = 'de';

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
});

async function dynamicActivate(locale: string) {
  const { messages } = await import(`../locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

const LocalizationProvider: FC = ({ children }: PropsWithChildren<{}>) => {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(defaultLocale);
  }, []);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default LocalizationProvider;
