import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    organizationId
    username
    firstName
    lastName
    email
  }
`;

export const USERS = gql`
  ${USER_FRAGMENT}
  query GetUsers($ids: [Float!], $name: String) {
    users(ids: $ids, name: $name) {
      ...UserFields
    }
  }
`;

export const USER = gql`
  ${USER_FRAGMENT}
  query ($id: Float!) {
    user(id: $id) {
      ...UserFields
    }
  }
`;
