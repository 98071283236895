/* eslint-disable react/prop-types */
import React, { FC, useEffect, useRef } from 'react';

const ReadyPlayerMe: FC<{ onAvatarExport: (url: string) => void }> = ({
  onAvatarExport,
}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  const subdomain = 'tepcon';

  const src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;

  useEffect(() => {
    const parse = (event: MessageEvent<any>) => {
      try {
        return JSON.parse(event.data);
      } catch (error) {
        return null;
      }
    };

    const subscribe = (event: MessageEvent<any>) => {
      const json = parse(event);

      if (json?.source !== 'readyplayerme') {
        return;
      }

      if (!frameRef || !frameRef.current) return;

      const frame = frameRef.current;

      if (json.eventName === 'v1.frame.ready') {
        frame.contentWindow?.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**',
          }),
          '*'
        );
      }

      if (json.eventName === 'v1.avatar.exported') {
        onAvatarExport(json.data.url);
      }

      if (json.eventName === 'v1.user.set') {
        // eslint-disable-next-line no-console
        console.log(
          `User with id ${json.data.id} set: ${JSON.stringify(json)}`
        );
      }
    };

    window.addEventListener('message', subscribe);

    return () => {
      window.removeEventListener('message', subscribe);
    };
  }, []);

  return (
    <iframe
      ref={frameRef}
      title="Ready Player Me"
      src={src}
      width="100%"
      height="100%"
      style={{ border: 'none', overflow: 'hidden', borderRadius: '5px' }}
      allow="camera *;microphone *"
    />
  );
};

export default ReadyPlayerMe;
