import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteProps,
} from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { authClient, instructorClient } from '../../apollo';
import { USERS } from '../../graphql/User';
import { User } from '../../types/User';
import { MeetingGroup } from '../../types/MeetingGroup';
import { MEETING_GROUPS } from '../../graphql/MeetingGroup';

enum ItemType {
  User = 1,
  Group = 2,
}

type GroupItem = {
  label: string;
  items: Item[];
};

type Item = {
  label: string;
  value: string;
  type: ItemType;
};

type UserAutoCompleteProps = Omit<AutoCompleteProps, 'value'> & {
  value: { users: number[]; groups: string[] };
  error?: string | undefined;
  translatedLabel?: string | undefined;
  onChange?: (arg: AutoCompleteChangeParams) => void;
};

const UserAutoComplete: FC<UserAutoCompleteProps> = (
  props: UserAutoCompleteProps
) => {
  const { error: validationError, translatedLabel } = props;

  const [filteredUsers, setFilteredUsers] = useState<GroupItem[] | undefined>(
    undefined
  );

  const [selection, setSelection] = useState<Item[] | undefined>(undefined);

  const updateUserDisplay = useCallback(async () => {
    const { value } = props;

    if (value) {
      const tempSelection: Item[] = [];

      await instructorClient
        .query<{ meetingGroups: MeetingGroup[] }>({
          query: MEETING_GROUPS,
          variables: { guids: value.groups },
        })
        .then(({ data }) => {
          const selectedGroups = data.meetingGroups.map((meetingGroup) => ({
            label: meetingGroup.name,
            value: meetingGroup.guid,
            type: ItemType.Group,
          }));

          tempSelection.push(...selectedGroups);
        });

      await authClient
        .query<{ users: User[] }>({
          query: USERS,
          variables: { ids: value.users },
        })
        .then(({ data }) => {
          const selectedUsers: Item[] = data.users.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id.toString(),
            type: ItemType.User,
          }));

          tempSelection.push(...selectedUsers);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });

      setSelection(tempSelection);
    }
  }, []);

  useEffect(() => {
    updateUserDisplay();
  }, [updateUserDisplay]);

  function handleChange(arg: AutoCompleteChangeParams) {
    const { value } = arg;
    const { onChange } = props;

    setSelection(value);

    const usersItems: Item[] = value.filter(
      (v: Item) => v.type == ItemType.User
    );

    const groupItems: Item[] = value.filter(
      (v: Item) => v.type == ItemType.Group
    );

    const userMappedValues = usersItems.map((v: Item) =>
      parseFloat(v.value.toString())
    );

    const groupMappedValues = groupItems.map((v: Item) => v.value.toString());

    if (onChange)
      onChange({
        ...arg,
        value: { users: userMappedValues, groups: groupMappedValues },
        target: {
          ...arg.target,
          value: { users: userMappedValues, groups: groupMappedValues },
        },
      });
  }

  const searchUsers = async (event: AutoCompleteCompleteMethodParams) => {
    const result: GroupItem[] = [];

    await instructorClient
      .query<{ meetingGroups: MeetingGroup[] }>({
        query: MEETING_GROUPS,
        variables: { name: event.query },
      })
      .then(({ data }) => {
        if (data.meetingGroups && data.meetingGroups.length > 0) {
          const foundGroups = data.meetingGroups.map((meetingGroup) => ({
            label: meetingGroup.name,
            value: meetingGroup.guid,
            type: ItemType.Group,
          }));

          result.push({ label: i18n._('Groups'), items: foundGroups });
        }
      });

    await authClient
      .query<{ users: User[] }>({
        query: USERS,
        variables: { name: event.query },
      })
      .then(({ data }) => {
        if (data.users && data.users.length > 0) {
          const foundUsers = data.users.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id.toString(),
            type: ItemType.User,
          }));

          result.push({ label: i18n._('Users'), items: foundUsers });
        }
      });

    setFilteredUsers(result);
  };

  const customSelectedItem = (item: Item) => (
    <span
      className="p-chips-token-label"
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: '27.5px',
        fontSize: '100% !important',
        fontWeight: item.type == ItemType.User ? 'initial' : 'bold',
      }}
    >
      {item.label}
    </span>
  );

  const groupedItemTemplate = (item: GroupItem) => (
    <div className="p-d-flex p-ai-center country-item">
      <div style={{ fontWeight: 'bold' }}>{item.label}</div>
    </div>
  );

  return (
    <span className="p-float-label">
      <AutoComplete
        {...props}
        field="label"
        value={selection}
        suggestions={filteredUsers}
        completeMethod={searchUsers}
        onChange={handleChange}
        selectedItemTemplate={customSelectedItem}
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
      />
      <label
        htmlFor="name"
        className={classNames({
          'p-error': validationError,
        })}
      >
        {translatedLabel && <Trans id={translatedLabel} />}
      </label>
    </span>
  );
};

export default UserAutoComplete;
