/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, PropsWithChildren, useState } from 'react';
import { classNames } from 'primereact/utils';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './MainLayout.scss';
import PrimeReact from 'primereact/api';
import ResizeDetector from 'react-resize-detector';
import AppTopbar from './components/AppTopbar';
import AppMenu from './components/AppMenu';

export const RTLContext = React.createContext(false);

type MainLayoutProps = PropsWithChildren<{}>;

const MainLayout: FC<MainLayoutProps> = ({ children }: MainLayoutProps) => {
  const [menuMode, setMenuMode] = useState('static');
  const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
  const [desktopMenuActive, setDesktopMenuActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [colorMode, setColorMode] = useState('light');
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('filled');
  const [isRTL, setRTL] = useState<boolean>(false);
  const [ripple, setRipple] = useState(true);
  const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
  const [menuTheme, setMenuTheme] = useState('light');
  const [topbarTheme, setTopbarTheme] = useState('blue');
  const [theme, setTheme] = useState('indigo');
  const [isInputBackgroundChanged, setIsInputBackgroundChanged] =
    useState(false);
  const [inlineMenuActive, setInlineMenuActive] = useState<any>({});
  const [newThemeLoaded, setNewThemeLoaded] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  PrimeReact.ripple = true;

  let topbarItemClick: boolean;
  let menuClick: boolean;

  const onThemeChange = (themeName: string) => {
    setTheme(themeName);
    const themeLink = document.getElementById('theme-css');
    const themeHref = `assets/theme/${theme}/theme-${colorMode}.css`;
    replaceLink(themeLink, themeHref);
  };

  const onColorModeChange = (mode: string) => {
    setColorMode(mode);
    setIsInputBackgroundChanged(true);

    if (isInputBackgroundChanged) {
      if (mode === 'dark') {
        setInputStyle('filled');
      } else {
        setInputStyle('outlined');
      }
    }

    if (mode === 'dark') {
      setMenuTheme('dark');
      setTopbarTheme('dark');
    } else {
      setMenuTheme('light');
      setTopbarTheme('blue');
    }

    const layoutLink = document.getElementById('layout-css');
    const layoutHref = `assets/layout/css/layout-${mode}.css`;
    replaceLink(layoutLink, layoutHref);

    const themeLink: HTMLElement | null = document.getElementById('theme-css');
    const srcAttribute: string | null | undefined =
      themeLink?.getAttribute('href');
    if (themeLink && srcAttribute) {
      const urlTokens = srcAttribute.split('/');
      urlTokens[urlTokens.length - 1] = `theme-${mode}.css`;
      const newURL = urlTokens.join('/');

      replaceLink(themeLink, newURL, () => {
        setNewThemeLoaded(true);
      });
    }
  };

  const replaceLink = (linkElement: any, href: string, callback?: any) => {
    if (isIE()) {
      linkElement.setAttribute('href', href);

      if (callback) {
        callback();
      }
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', `${id}-clone`);

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);

        if (callback) {
          callback();
        }
      });
    }
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;

    if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
    else setMobileMenuActive((prevState) => !prevState);

    event.preventDefault();
  };

  const onMobileTopbarButtonClick = (event: any) => {
    setMobileTopbarActive((prevState) => !prevState);
    event.preventDefault();
  };

  const isDesktop = () => window.innerWidth > 1024;

  const isHorizontal = () => menuMode === 'horizontal';

  const isSlim = () => menuMode === 'slim';

  const isIE = () =>
    /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);

  const layoutContainerClassName = classNames(
    'layout-wrapper ',
    `layout-menu-${menuTheme} layout-topbar-${topbarTheme}`,
    {
      'layout-menu-static': menuMode === 'static',
      'layout-menu-overlay': menuMode === 'overlay',
      'layout-menu-slim': menuMode === 'slim',
      'layout-menu-horizontal': menuMode === 'horizontal',
      'layout-menu-active': desktopMenuActive,
      'layout-menu-mobile-active': mobileMenuActive,
      'layout-topbar-mobile-active': mobileTopbarActive,
      'layout-rightmenu-active': rightMenuActive,
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple,
      'layout-rtl': isRTL,
    }
  );

  return (
    <RTLContext.Provider value={false}>
      <div className={layoutContainerClassName}>
        <AppTopbar
          onMenuButtonClick={onMenuButtonClick}
          onMobileTopbarButtonClick={onMobileTopbarButtonClick}
        />

        <div className="menu-wrapper">
          <div className="layout-menu-container">
            <AppMenu />
          </div>
        </div>

        <div className="layout-main">
          <div className="layout-content" style={{ display: 'flex' }}>
            <ResizeDetector
              handleWidth
              handleHeight
              onResize={() => window.dispatchEvent(new Event('resize'))}
            />

            {children}
          </div>
        </div>
      </div>
    </RTLContext.Provider>
  );
};

export default MainLayout;
