/* eslint-disable no-restricted-globals */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import FullCalendar, {
  EventApi,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import { Dialog } from 'primereact/dialog';
import { Form, Formik } from 'formik';
import { classNames } from 'primereact/utils';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { instructorClient } from '../../apollo';
import {
  ADD_UPDATE_MEETING,
  MEETINGS,
  REMOVE_MEETING,
} from '../../graphql/Meeting';
import { Meeting } from '../../types/Meeting';
import UserAutoComplete from '../../components/input/UserAutoComplete';
import TepInputText from '../../components/input/TepInputText';
import TepInputTextarea from '../../components/input/TepInputTextarea';
import TepDropdown from '../../components/input/TepDropdown';
import TepCalendar from '../../components/input/TepCalendar';
import { RootState } from '../../stores';
import InstructionAutoComplete from '../../components/input/InstructionAutoComplete';

import './meeting.scss';
import loftThumbnail from './images/Loft.jpg';
import garageThumbnail from './images/Garage.jpg';
import beachThumbnail from './images/Beach.jpg';

type MeetingEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  meeting: Meeting;
};

type FormValues = {
  guid: string | undefined;
  ownerId: number;
  name: string;
  description: string;
  lang: string;
  start: Date;
  end: Date;
  moderators: { users: number[]; groups: string[] };
  attendees: { users: number[]; groups: string[] };
  instruction: string;
  room: string;
};

type RoomOption = {
  label: string;
  value: string;
  thumbnail: string;
};

const languages = [
  { label: 'Deutsch', value: 'de' },
  { label: 'English', value: 'en' },
];

const MeetingView: FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const toast = useRef<Toast>(null);
  const calendar = useRef<FullCalendar>(null);

  const embedded = location.pathname.includes('embed');

  const rooms: RoomOption[] = [
    { label: i18n._('Rooms.Loft'), value: 'Loft', thumbnail: loftThumbnail },
    {
      label: i18n._('Rooms.Garage'),
      value: 'Workshop',
      thumbnail: garageThumbnail,
    },
    { label: i18n._('Rooms.Beach'), value: 'Beach', thumbnail: beachThumbnail },
  ];

  const defaultFormValues = Object.freeze({
    guid: undefined,
    ownerId: 0,
    name: '',
    description: '',
    lang: 'de',
    start: new Date(Date.now()),
    end: new Date(Date.now()),
    moderators: { users: [], groups: [] },
    attendees: { users: [], groups: [] },
    instruction: '',
    room: rooms[0].value,
  });

  const [events, setEvents] = useState<MeetingEvent[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [editRights, setEditRights] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<EventApi | undefined>(
    undefined
  );
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);

  function showErrorToast(title: string, message: string) {
    if (toast && toast.current) {
      toast.current.show({
        severity: 'error',
        summary: title,
        detail: message,
      });
    }
  }

  const meetingsQuery = useCallback(async () => {
    instructorClient
      .query<{ meetings: Meeting[] }>({ query: MEETINGS })
      .then(({ data }) => {
        const mappedEvents: MeetingEvent[] = data.meetings.map((meeting) => ({
          id: meeting.guid,
          title: meeting.name,
          start: meeting.start,
          end: meeting.end,
          meeting,
        }));

        setEvents(mappedEvents);

        setLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    meetingsQuery();
  }, [meetingsQuery]);

  async function onSelect(arg: EventClickArg) {
    const { event } = arg;
    const { meeting } = event.extendedProps;

    setCurrentEvent(event);

    setFormValues({
      guid: meeting.guid,
      ownerId: meeting.ownerId,
      name: meeting.name,
      description: meeting.description,
      lang: meeting.lang,
      start: new Date(meeting.start),
      end: new Date(meeting.end),
      moderators: meeting.moderators,
      attendees: meeting.attendees,
      instruction: meeting.instruction,
      room: meeting.room,
    });

    setEditRights(
      user &&
        (meeting.ownerId == user?.id ||
          meeting.moderatorIds.includes(Number.parseFloat(user.id.toString())))
    );

    setDisplayDialog(true);
  }

  function onSubmit(values: typeof formValues): void {
    instructorClient
      .mutate<{ addUpdateMeeting: Meeting }>({
        mutation: ADD_UPDATE_MEETING,
        variables: values,
      })
      .then(({ data }) => {
        if (data) {
          const { addUpdateMeeting: meeting } = data;

          if (currentEvent) {
            currentEvent.setProp('title', values.name);
            currentEvent.setStart(values.start);
            currentEvent.setEnd(values.end);

            currentEvent.setExtendedProp('meeting', meeting);
          } else if (calendar && calendar.current) {
            calendar.current.getApi().addEvent({
              id: meeting.guid,
              title: meeting.name,
              start: meeting.start,
              end: meeting.end,
              meeting,
            });
          }

          setDisplayDialog(false);
        }
      })
      .catch(({ graphQLErrors }) => {
        showErrorToast(
          i18n._('Toasts.SaveFailed.Title'),
          graphQLErrors[0].message
        );
      });
  }

  function onDrop(arg: EventDropArg) {
    const { event } = arg;
    const { meeting } = event.extendedProps;

    const dateUpdate = {
      guid: meeting.guid,
      start: event.start,
      end: event.end ?? event.start,
    };

    instructorClient
      .mutate<{ addUpdateMeeting: Meeting }>({
        mutation: ADD_UPDATE_MEETING,
        variables: dateUpdate,
      })
      .then(({ data }) => {
        if (data) event.setExtendedProp('meeting', data.addUpdateMeeting);
      })
      .catch(({ graphQLErrors }) => {
        showErrorToast(
          i18n._('Toasts.SaveFailed.Title'),
          graphQLErrors[0].message
        );
        arg.revert();
      });
  }

  function onResize(arg: EventResizeDoneArg) {
    const { event } = arg;
    const { meeting } = event.extendedProps;

    const dateUpdate = {
      guid: meeting.guid,
      start: event.start,
      end: event.end ?? event.start,
    };

    instructorClient
      .mutate<{ addUpdateMeeting: Meeting }>({
        mutation: ADD_UPDATE_MEETING,
        variables: dateUpdate,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data) event.setExtendedProp('meeting', data.addUpdateMeeting);
      })
      .catch(({ graphQLErrors }) => {
        showErrorToast(
          i18n._('Toasts.SaveFailed.Title'),
          graphQLErrors[0].message
        );
        arg.revert();
      });
  }

  function onDateClick(arg: DateClickArg) {
    const currentDate = new Date();

    const userId = Number.parseFloat((user?.id ?? 0).toString());

    setFormValues({
      ...defaultFormValues,
      ownerId: Number.parseFloat((user?.id ?? 0).toString()),
      moderators: { users: [userId], groups: [] },
      start: new Date(
        arg.date.setHours(currentDate.getHours(), currentDate.getMinutes())
      ),
      end: new Date(
        arg.date.setHours(currentDate.getHours(), currentDate.getMinutes()) +
          1 * 60 * 60 * 1000
      ),
    });

    setEditRights(true);
    setDisplayDialog(true);
  }

  function onRemove() {
    if (currentEvent) {
      const { meeting } = currentEvent.extendedProps;

      const dateUpdate = {
        guid: meeting.guid,
      };

      instructorClient
        .mutate<{ removeMeeting: Meeting }>({
          mutation: REMOVE_MEETING,
          variables: dateUpdate,
        })
        .then(() => {
          currentEvent.remove();

          setDisplayDialog(false);
        })
        .catch(({ graphQLErrors }) => {
          showErrorToast(
            i18n._('Toasts.RemoveFailed.Title'),
            graphQLErrors[0].message
          );
        });
    }
  }

  function onHide() {
    setCurrentEvent(undefined);
    setDisplayDialog(false);
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).max(100).required(),

    description: Yup.string().max(250),

    start: Yup.date().required(),

    end: Yup.date()
      .when('start', (start, schema) => start && schema.min(start))
      .required()
      .default(undefined),
  });

  const myIcon = (
    <>
      {editRights && (
        <>
          <button
            className="p-dialog-header-icon p-dialog-header-close p-link"
            form="editForm"
            type="submit"
          >
            <span className="p-dialog-header-close-icon pi pi-check" />
          </button>
          {currentEvent && (
            <button
              className="p-dialog-header-icon p-dialog-header-close p-link"
              form="editForm"
              type="button"
              onClick={onRemove}
            >
              <span className="p-dialog-header-close-icon pi pi-trash" />
            </button>
          )}
        </>
      )}
    </>
  );

  const roomOptionTemplate = (option: RoomOption) => (
    <div>
      <div
        style={{
          borderRadius: '4px',
          background: `#ffffff url(${option.thumbnail}) center / cover no-repeat`,
          aspectRatio: '16 / 9',
        }}
        className="p-shadow-2"
      >
        <div className="p-mb-3 room-label">{option.label}</div>
      </div>
    </div>
  );

  if (loading) return null;

  return (
    <div
      className={classNames('card p-d-flex p-flex-column', {
        'p-input-filled': embedded,
      })}
      style={{ flex: '1 1 auto', height: embedded ? '100vh' : '' }}
    >
      <Toast ref={toast} />

      <h2 style={{ marginTop: '0' }}>
        <Trans>Meetings</Trans>
      </h2>

      <FullCalendar
        ref={calendar}
        locales={[{ code: i18n.locale }]}
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          start: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth timeGridWeek',
        }}
        events={events}
        eventClick={onSelect}
        eventDisplay="block"
        editable
        eventStartEditable
        eventDrop={onDrop}
        eventResize={onResize}
        dateClick={onDateClick}
        height="100%"
      />
      <Dialog
        header={i18n._('Edit Meeting')}
        visible={displayDialog}
        style={{ width: '50rem' }}
        onHide={onHide}
        icons={myIcon}
        appendTo="self"
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={formValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, handleChange, initialValues, errors, values }) => (
            <Form id="editForm" noValidate onSubmit={handleSubmit}>
              <div className="p-grid p-fluid">
                <div className="p-field p-col-12">
                  <TepInputText
                    id="name"
                    name="name"
                    type="text"
                    defaultValue={initialValues.name}
                    onChange={handleChange}
                    autoFocus
                    error={errors.name}
                    translatedLabel="Name"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field  p-col-12">
                  <TepInputTextarea
                    id="description"
                    name="description"
                    defaultValue={initialValues.description}
                    onChange={handleChange}
                    style={{ resize: 'vertical' }}
                    error={errors.description}
                    translatedLabel="Description"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field p-col-12">
                  <UserAutoComplete
                    id="owner"
                    name="owner"
                    value={{ users: [values.ownerId], groups: [] }}
                    multiple
                    translatedLabel="Owner"
                    disabled
                  />
                </div>
                <div className="p-field p-col-12">
                  <UserAutoComplete
                    id="moderators"
                    name="moderators"
                    value={values.moderators}
                    onChange={handleChange}
                    multiple
                    autoHighlight
                    translatedLabel="Moderators"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field p-col-12">
                  <UserAutoComplete
                    id="attendees"
                    name="attendees"
                    value={values.attendees}
                    onChange={handleChange}
                    multiple
                    autoHighlight
                    translatedLabel="Attendees"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field p-col-12 p-md-6">
                  <TepDropdown
                    id="room"
                    name="room"
                    optionLabel="label"
                    value={values.room}
                    options={rooms}
                    onChange={handleChange}
                    translatedLabel="Room"
                    disabled={!editRights}
                    itemTemplate={roomOptionTemplate}
                  />
                </div>
                <div className="p-field p-col-12 p-md-6">
                  <TepDropdown
                    id="lang"
                    name="lang"
                    optionLabel="label"
                    value={values.lang}
                    options={languages}
                    onChange={handleChange}
                    translatedLabel="Language"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field p-col-12">
                  <InstructionAutoComplete
                    id="instruction"
                    name="instruction"
                    value={values.instruction}
                    onChange={handleChange}
                    dropdown
                    forceSelection
                    autoHighlight
                    translatedLabel="Instruction"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field p-col-12 p-md-6">
                  <TepCalendar
                    id="start"
                    name="start"
                    dateFormat="dd.mm.yy"
                    showTime
                    hourFormat="24"
                    mask="99.99.9999 99:99"
                    stepMinute={5}
                    showIcon
                    value={initialValues.start}
                    onChange={(event) => {
                      if (event.value) {
                        if (event.value instanceof Date)
                          values.start = event.value;
                        handleChange(event);
                      }
                    }}
                    error={errors.start}
                    translatedLabel="Start"
                    disabled={!editRights}
                  />
                </div>
                <div className="p-field p-col-12 p-md-6">
                  <TepCalendar
                    id="end"
                    name="end"
                    dateFormat="dd.mm.yy"
                    showTime
                    hourFormat="24"
                    mask="99.99.9999 99:99"
                    stepMinute={5}
                    showIcon
                    value={initialValues.end}
                    onChange={(event) => {
                      if (event.value) {
                        if (event.value instanceof Date)
                          values.end = event.value;
                        handleChange(event);
                      }
                    }}
                    error={errors.end}
                    translatedLabel="End"
                    disabled={!editRights}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default MeetingView;
