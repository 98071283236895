/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { FC, MouseEventHandler } from 'react';
import { useHistory } from 'react-router-dom';

type AppTopbarProps = {
  onMenuButtonClick: MouseEventHandler<HTMLButtonElement>;
  onMobileTopbarButtonClick: MouseEventHandler<HTMLButtonElement>;
};

const AppTopbar: FC<AppTopbarProps> = ({
  onMenuButtonClick,
  onMobileTopbarButtonClick,
}: AppTopbarProps) => {
  const history = useHistory();

  return (
    <div className="layout-topbar p-shadow-4">
      <div className="layout-topbar-left">
        <button
          type="button"
          style={{ cursor: 'pointer' }}
          className="layout-topbar-logo p-link"
          onClick={() => history.push('/')}
        >
          <img
            id="app-logo"
            src="assets/layout/images/logo-light.svg"
            alt="ultima-layout"
            style={{ height: '2.25rem' }}
          />
        </button>
        <button
          type="button"
          className="layout-menu-button p-shadow-6 p-link"
          onClick={onMenuButtonClick}
        >
          <i className="pi pi-chevron-right" />
        </button>
        <button type="button" className="layout-topbar-mobile-button p-link">
          <i
            className="pi pi-ellipsis-v fs-large"
            onClick={onMobileTopbarButtonClick}
          />
        </button>
      </div>
    </div>
  );
};

export default AppTopbar;
